.sunE-main-title .line-h {
  height: unset;
}

.turn_lesson_report_detail-container * {
  color: #4D4D4D;
}

.turn_lesson_report_detail-container {
  overflow: auto;
  max-height: calc(100vh - 180px);
  padding-bottom: 32px;

  .turn_lesson_report_detail-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .turn_lesson_report_detail-item {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-right: 8px;

      .turn_lesson_report_detail-name {
        font-size: 18px;
        width: 200px;
        min-width: 200px;
      }

      .turn_lesson_report_detail-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border-color: #000;
        border-style: solid;
        height: 28px;
        width: 60px;
        min-width: 60px;
      }
    }
  }
}