.entrance-score-container {
  width: 100%;
  height: 100vh;
}

.entrance-score-header {
  height: 40%;
  overflow: hidden;

  .header-part {
    height: 50%;
    display: flex;
  }

  .header-part.header-top {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 12px;

    .btn-back {
      color: #00BEB4;
      background-color: #FFFFFF;
      background-image: unset;
    }

    .btn-back:hover {
      opacity: 0.9;
    }

    .entrance-score-label {
      color: #FFFFFF;
      font-size: 30px;
      text-align: center;
      font-weight: 700;
    }

  }

  .entrance-score-header-divider {
    width: 100%;
    height: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  .header-part.header-bottom {
    justify-content: center;

    .entrance-score-value {
      font-size: 90px;
      font-weight: 700;
      color: #F29500;
      text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
        1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
      margin-right: 48px;
      display: flex;
      align-items: center;
    }

    .entrance-score-time {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      margin-left: 8px;
      letter-spacing: 1px;
    }

    .size-text-info {
      color: #FFFFFF;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }

  .entrance-score-icon {
    width: 26px;
    height: 26px;
    object-fit: cover;
  }
}

.entrance-score-body {
  height: 60%;
  overflow: hidden;
}