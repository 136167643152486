@import "/src/_styles/mixin";

.policyPage {
  position: relative;

  // background-color: var(--white-color);
  .slider_bg {
    width: 100%;
    position: relative;
  }

  .bg_side {
    position: fixed;
    top: 18%;
    right: 48px;
    left: 0;
    // height: 10rem;
    width: unset;
    height: 100%;
    z-index: -1;

    @include screen_mobile {
      top: 10%;
      top: 5%;
      width: 100%;
      height: 100%;
      left: 0 !important;
      right: 0 !important;
    }
  }

  .policy-name-absolute {
    position: absolute;
    top: 32px;
    left: 48px;
    z-index: 10;

    p {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 30px;
    }
  }

  .content {
    // padding-bottom: 10rem;
    z-index: 10;
    padding: 0 3rem;
    margin-top: 2.6rem;
    // background-color: var(--white-color);

    @include screen_mobile {
      margin-top: 0;
      padding: 0
    }

    .content_categories {
      width: 25%;
      height: fit-content;
      border-radius: 1rem;
      padding: 0.5rem 0 0.75rem;
      margin-right: clamp(1rem, 2.65vw, 2.45rem);
      position: sticky;
      top: 120px;

      .content_categories-header {
        padding: 0.25rem clamp(0.75rem, 1.75vw, 1.75rem) 0.75rem;
        font-size: 1.175rem;

        @include screen_mobile {
          font-size: clamp(0.875rem, 5vw, 1.125rem);
          padding: 0.25rem 0 0.75rem;
        }
      }

      .categories_list {

        .category_item {
          padding: 0.5rem clamp(0.75rem, 1.75vw, 1.75rem);

          @include screen_mobile {
            background-color: var(--white-color) !important;
            position: relative;
            z-index: 2;
            padding: 0.65rem 1rem 0.65rem 2.2rem;

            &:not(&:last-child) {
              border-bottom: 1px solid var(--border-color);
            }
          }

          @media screen and (max-height: 800px) {
            padding: 0.1rem 1rem 0.1rem 2.2rem;
          }

          &:hover div {
            color: var(--primary-green);
          }

          .category_item-title {
            font-size: 16px;

            @include screen_mobile {
              font-size: clamp(0.875rem, 5vw, 1rem);
            }
          }
        }

        .category_item--selected {
          background-color: #c6fbdd;

          &:hover div {
            color: var(--text-color);
          }
        }

        .border--selected {
          position: absolute;
          left: 0;
          top: 0;
          width: clamp(0.35rem, 0.6vw, 0.45rem);
          height: 100%;
          background-color: var(--primary-green);
        }
      }
    }

    .content_policy {
      flex: 1;
    }

    .img_policy {
      width: 32px;
      height: 32px;
    }

    @include screen_mobile {
      // padding-bottom: 7rem;
    }

    .content_1 {
      // padding: 0 0 0rem 10%;
      z-index: 10;

      @include screen_mobile {
        // padding-left: 4%;
      }

      .list_about,
      .list_item_dot {

        li,
        .item_dot {
          &::before {
            content: "\2022 ";
            color: red;
            padding-right: 0.5em;
            font-size: 1.7rem;

            @include screen_mobile {
              font-size: 1.4rem;
            }
          }
        }
      }

      .img_about {
        max-height: 80%;
        max-width: 40%;

        @include screen_mobile {
          max-height: 60% !important;
          // height: 10rem;
          max-width: 96% !important;
          float: right;
          position: relative;
        }
      }

      .content_rules {
        // padding-right: 6rem;
        margin-top: 0;

        @include screen_mobile {
          // padding-right: 4%;
        }
      }
    }

    .transparent_side {
      height: 38rem;
      width: 100%;
      position: relative;

      @include screen_mobile {
        height: 33rem;
      }
    }

    .content_2,
    .content_3 {
      // padding: 0 6rem 3rem 7rem;

      @include screen_mobile {
        // padding-left: 4%;
        // padding-right: 4%;
      }
    }
  }

  b {
    font-size: inherit;
  }

  .first-contents {
    .mg-bottom-0-9 {
      @include screen_mobile {
        margin-bottom: 0;
      }
    }
  }
}