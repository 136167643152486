.sunE-right-container.add_student_ic_container {
  padding: 30px 14px 0 14px;
}

.add_student_ic_container {
  padding-bottom: 0;
  overflow: hidden !important;

  .add-student-ic-content {
    display: flex;
    height: 100%;

    .add-student-way {
      width: 38%;

      .add-student-way-search {
        padding-bottom: 16px;
        border-bottom: 1px solid #666;
      }

      .add-student-way-file {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .way-file-step {
          font-size: 16px;
        }
      }

      .add-student-way-title {
        font-size: 18px;
        font-weight: 700;
      }

      .add-student-way-action {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        input[type="file"] {
          display: none;
        }
      }
    }

    .add-student-list-content {
      padding-left: 16px;
      height: 88%;
      margin-left: 16px;
      border-left: 1px solid #666;
      flex: 1;
      display: flex;
      flex-direction: column;

      .add-student-list-heading {
        font-size: 18px;
        font-weight: 700;
      }

      .add-student-list {
        padding-right: 16px;
        padding-bottom: 16px;
        height: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;

        .add-student-item {
          width: 100%;
          border-radius: 16px;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          gap: 16px;

          .add-student-item-image {
            width: 70px;
            height: 70px;
            border-radius: 100%;
            padding: 2px;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
            }
          }

          .add-student-info {
            flex: 1;
          }

          .add-student-info-name {
            font-weight: 700;
          }
        }
      }
    }
  }

  .input_text_base {
    height: 44px;
    padding: 0 12px;
    margin-top: 8px;
    margin-bottom: 16px;

    .icon_label {
      margin-right: 8px !important;
      width: 24px !important;
    }

    .input_text {
      font-size: 14px !important;
    }
  }
}