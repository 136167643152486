.active-license-student-container {
  padding: 16px 0;
  background-color: #FFFFFF;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.active-license-student-list {
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 100%;
  overflow-y: auto;
}

.active-license-student-item {
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  width: 49%;
}

.active-license-student-info {
  display: block;
  flex: 1;
}

.active-license-student-img {
  width: 70px;
  height: 70px;
  padding: 2px;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.active-license-student-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.active-license-student-action {
  display: flex;
  gap: 20px;
  align-self: center;
  padding-top: 16px;
}

.check-all-student-btn {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
}

.chk-custom-gr input:checked+.check-all-student-btn:after {
  right: 5px;
  top: -6px;
  left: unset;
}