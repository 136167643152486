@import "/src/_styles/mixin";

$red: #e22028;
$border-color: #4a4848;

.input_text_base_container {
  position: relative;

  .error_input {
    span {
      color: $red;
      font-size: 18px;
    }
  }

  .error_text {
    margin-bottom: 9px;
  }

  .error_text_absolute {
    position: absolute;
    bottom: calc(100% + 2px);
  }

  .error_text_absolute_new {
    position: absolute;
    top: calc(100% + 2px);
  }

  .input_text_base {
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0px 22px;
    border: 1px solid $border-color;
    border-radius: 10px;
    margin-bottom: 22px;

    @media screen and (max-width: 768px) {
      height: 44px;

      svg {
        width: 18px;
      }
    }

    &.focus {
      border-width: 1px;

      .custom_icon {
        display: none;
      }
    }

    &.warning {
      border-color: $red !important;
      border-width: 2px !important;
      border-style: solid !important;
      padding-left: 23px;
      padding-right: 23px;
    }

    &.success {
      border-color: #36ae4a !important;
      border-width: 2px !important;
      border-style: solid !important;
      padding-left: 23px;
      padding-right: 23px;
    }

    .input_text_base_content {
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;
      padding: 1px 0px;

      .icon_label {
        width: 31px;
        margin-right: 26px;

        @include screen_mobile {
          margin-right: 0 !important;
        }
      }

      .input_text {
        height: 100%;
        border: none;
        font-size: 18px;
        line-height: 21px;
        flex: 1;
        background-color: transparent;

        &:focus {
          outline: none;
        }

        @include screen_mobile {
          font-size: 0.9rem !important;
        }
      }
    }

    .toggle_password {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  input[type="password"] {
    &::-ms-reveal {
      display: none;
    }
  }

  input {
    font-family: "Myriadpro-Regular";

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: "Myriadpro-Regular";
      color: #8e9298;
    }
  }
}

::-ms-reveal {
  display: none;
}