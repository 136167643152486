#p-exam-test,
#c-history-test,
#c-detail-history-test {
  flex: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header-view {
    display: flex;
    // height: 60px;
    justify-content: space-between;
    margin: 16px 8px 8px 8px;

    .title-header {
      border-left: 5px solid #00bbb6;
      font-size: 32px;
      padding-left: 8px;
      font-family: "Myriadpro-SemiBold";
      color: #00bbb6;
    }

    .filter {
      display: flex;
      justify-content: center;
      align-items: center;

      .with-200 {
        width: 200px;
      }

      .btn-history {
        background-image: linear-gradient(to right, #00b9b7, #00e1a0);
        display: flex;
        border: none;
        padding: 7.5px 16px;
        margin: 0px 6px;
        border-radius: 20px;
        align-items: center;

        span {
          color: #fff;
          margin-left: 6px;
        }
      }
    }

    .item-filter-dropdown {
      border: 1px solid #ccc;
      padding: 8px 16px;
      border-radius: 20px;
      min-width: 120px;
      display: flex;
      justify-content: space-between;
      background-color: #b7f3e5;
    }
  }

  .body-view {
    display: flex;
    flex: 1;
    overflow: hidden;

    .tab-view {
      width: 250px;
      margin: 8px;

      .tab-item {
        cursor: pointer;
      }
    }

    .list-view {
      flex: 1;
      margin: 8px;
      overflow: auto;
      padding: 0px 8px;

      .unit-test {
        display: flex;
        background-color: #fefefe;
        margin: 10px 0px;
        padding: 8px 16px;
        border-radius: 8px;
        box-shadow: 0px 1px 3px #707070;
        cursor: pointer;

        img {
          object-fit: contain;
        }

        .content-view {
          flex: 1;
          margin-left: 28px;

          .title {
            font-family: "Myriadpro-SemiBold";
            color: #000000;
            font-size: 18px;
          }

          .description {
            color: #000000;
            font-size: 14px;
          }

          .time-date {
            font-family: "Myriadpro-SemiBold";
            color: #000000;
            font-size: 14px;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;

      .col-md-6 {
        width: 50%;
        padding: 8px;

        .unit-test {
          display: flex;
          background-color: #fefefe;
          padding: 8px 16px;
          border-radius: 8px;
          box-shadow: 0px 1px 3px #707070;

          img {
            object-fit: contain;
          }

          .content-view {
            flex: 1;
            margin-left: 16px;
          }

          .title {
            font-family: "Myriadpro-SemiBold";
            color: #000000;
            font-size: 18px;
          }

          .description {
            color: #000000;
            font-size: 14px;
          }

          .content-info {
            margin-left: 8px;
          }

          .time-date {
            font-family: "Myriadpro-SemiBold";
            color: #000000;
            font-size: 14px;
          }
        }
      }
    }

    .sunE-no-class {
      margin-top: 60px;

      p {
        font-size: 22px;
      }
    }
  }
}

#c-history-test,
#c-detail-history-test {
  .header-view {
    .title-header {
      border-left: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        object-fit: contain;
      }

      span {
        font-size: 32px;
        padding-left: 8px;
        font-family: "Myriadpro-SemiBold";
        color: #00bbb6;
        margin-left: 6px;
      }
    }

    .flex-1 {
      flex: 1;
    }
  }

  .body-view {
    overflow: auto;
    width: 100%;
    flex-direction: column;

    .score-info-history {
      display: flex;
      text-align: center;

      .item-score {
        width: 33.33%;
        display: flex;
        flex-direction: column;

        label {
          font-size: 22px;
        }

        span {
          font-family: "Myriadpro-SemiBold";
          font-size: 26px;
        }
      }
    }

    .detail-history {
      padding: 24px;
      background-color: #fff;
      margin: 8px 5%;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;

      .col-2 {
        width: 16.66%;
      }

      .item-answer {
        height: 68px;
        border-radius: 16px;
        margin: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        cursor: pointer;
      }

      .item-answer.true {
        background-color: #8dc63f;
        border: 1px solid #009444;
      }

      .item-answer.false {
        background-color: #d68087;
        border: 1px solid #be1e2d;
      }
    }
  }

  .footer-view {
    text-align: center;
    margin: 24px;

    .btn-line-blue {
      font-size: 18px;
    }
  }
}

#c-do-test-exam {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #fff;

  .sunE-container {
    height: 100vh;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;

    .scrollbar-custom {
      overflow: auto;
    }

    .header-modal {
      height: 60px;
      background-color: #fefefe;
      margin: 0px;
      box-shadow: 0px 0px 2px #707070;
      display: flex;
      justify-content: space-between;
      padding: 8px 30px;
      margin-top: -36px;

      .title-header {
        display: flex;
        align-items: center;
        flex: 1;
        // justify-content: space-between;

        img {
          object-fit: cover;
          height: 28px;
          cursor: pointer;
        }
      }

      span {
        margin-top: 3px;
        font-size: 28px;
        padding-left: 8px;
        font-family: "Myriadpro-SemiBold";
        color: #00bbb6;
        margin-left: 6px;
        white-space: nowrap;
        overflow: hidden;
      }

      .flex-1 {
        align-items: center;
      }

      .btn {
        border: none;
        padding: 7.5px 24px;
        margin: 0px 6px;
        border-radius: 20px;
        color: #fff;
        margin-left: 6px;
        height: 39px;
        font-family: "Myriadpro-SemiBold";
        font-size: 18px;
        margin-top: 3px;
      }

      .btn.btn-overview {
        background-image: linear-gradient(to right, #00b9b7, #00e1a0);
      }

      .btn.btn-end {
        background-color: #f08b01;
      }
    }
  }

  // Questions Container

  .questions {
    width: 80%;
    margin: 24px 5rem 24px auto;
    min-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      margin-right: 2rem;
    }
  }

  .checkbox_review_container {
    // margin-left: 3rem;
    padding-left: 3rem;
    position: absolute;
    right: 0;
    top: 1.5rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .col-6 {
      width: 50%;
    }

    .col-12 {
      width: 100%;
    }
  }

  .footer-exam {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;

    .real-timing {
      font-size: 26px;
      border: 1px solid #707070;
      border-radius: 12px;
      padding: 3px 24px;
      margin: 0px 16px;
    }
  }

  .btn-primary {
    border: none;
    padding: 0px 24px;
    margin: 0px 6px;
    border-radius: 20px;
    color: #fff;
    margin-left: 6px;
    height: 39px;
    font-family: "Myriadpro-SemiBold";
    font-size: 18px;
    margin-top: 3px;
    background-image: linear-gradient(to right, #00b9b7, #00e1a0);
    display: flex;
    align-items: center;

    &:disabled {
      background: #70707070;
    }
  }
}

.instruction_container {
  overflow-y: auto;
  overflow-x: auto;
  margin: 2rem;

  .instruction_wrapper {
    overflow-y: auto;
    overflow-x: auto;

    .title_exam {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      max-width: 100%;
      padding-right: 23rem;

      @media screen and (max-width: 800px) {
        padding-right: 5rem;
      }
    }

    .infor_exam_container {
      // width: 100%;
      max-width: 100rem;
      background-color: #fefefe;
      padding: 1rem;
      border-radius: 12px;
      box-shadow: 0px 1px 3px #707070;
      margin: 1rem 0;

      .infor_exam_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 1rem;
        // padding: 2rem 4rem;
      }

      .exam_instruction_container {
        width: 100%;
        background-color: #e6e6e6;
        padding: 0.8rem 0.8rem 1rem;
        border-radius: 10px;

        h2 {
          color: #1a8cff;
          text-align: center;
          font-size: 1.3rem;
          margin-bottom: 2rem;
        }

        .instruction_content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media screen and (max-width: 800px) {
            flex-direction: column;
          }

          .instruction_list {
            width: 50%;

            @media screen and (max-width: 800px) {
              width: 100%;
            }

            .instruction_item {
              display: flex;
              align-items: center;
              margin: 1rem 0;

              .isntruction_item_img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                margin-right: 2rem;

                .img_icon_instruction {
                  height: 1.7rem;
                  width: auto;
                }
              }

              p {
                font-size: 1.1rem;
              }
            }
          }

          .instruction_content_right {
            width: 50%;
            padding: 0.5rem 0 0.5rem 2rem;
            border-left: 1px solid #333;

            @media screen and (max-width: 800px) {
              width: 100%;
              border-left: none;
              border-top: 1px solid #333;
              margin-top: 3rem;
            }
          }
        }
      }
    }
  }
}

.modal-overview-exam {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: gray;
    opacity: 0.5;
    z-index: 1;
  }

  .modal-overview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    height: 100vh;

    .modal-form {
      padding: 24px;
      background-color: #fff;
      border-radius: 24px;
      width: 69%;

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 26px;
          color: #00bab7;
          font-weight: bolder;
        }

        .btn {
          border: none;
          padding: 7.5px 24px;
          margin: 0px 6px;
          border-radius: 20px;
          color: #fff;
          margin-left: 6px;
          height: 39px;
          font-family: "Myriadpro-SemiBold";
          font-size: 18px;
          margin-top: 3px;
        }

        .btn.btn-go-back {
          background-image: linear-gradient(to right, #00b9b7, #00e1a0);
        }
      }

      .modal-body {
        margin-top: 16px;
        max-height: 50vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .note-question {
          display: flex;
          justify-content: center;
          align-items: center;

          .item-note {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 6px 25px;
          }

          .item-active {
            padding: 24px 36px;
            border: 2px solid #1b75bc;
            border-radius: 28px;
            margin: 8px;
            background-color: #fff;
          }

          .item-review {
            padding: 24px 36px;
            border: 2px solid #F7931E;
            border-radius: 28px;
            margin: 8px;
            background-color: #FFB776;
          }

          .item-not-active {
            padding: 24px 36px;
            border: 2px solid #939598;
            border-radius: 28px;
            margin: 8px;
            background-color: #e6e7e8;
          }

          .item-answer-true {
            padding: 24px 36px;
            border-radius: 28px;
            margin: 8px;
            background-color: #8dc63f;
            border: 1px solid #009444;
          }

          .item-answer-false {
            padding: 24px 36px;
            border-radius: 28px;
            margin: 8px;
            background-color: #d68087;
            border: 1px solid #be1e2d;
          }
        }

        .list-questions {
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          overflow: auto;

          .item-question {
            width: 10%;
            padding: 12px 24px;
            border-radius: 28px;
            margin: 8px 1.25%;
            text-align: center;
            width: 80px;
            cursor: pointer;
          }

          .item-question.review-later {
            border: 2px solid #F7931E;
            background-color: #FFB776;
          }

          .item-question.active {
            border: 2px solid #1b75bc;
          }

          .item-question.not-active {
            border: 2px solid #939598;
            background: #e6e7e8;
          }

          .item-question.answer-true {
            background-color: #8dc63f;
            border: 1px solid #009444;
          }

          .item-question.answer-false {
            background-color: #d68087;
            border: 1px solid #be1e2d;
          }
        }
      }
    }
  }
}

#c-result-exam {
  display: flex;
  flex-direction: column;
  height: calc(100vh + 20px);
  position: absolute;
  top: -20px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 10%;
  z-index: 3;

  .header-result {
    margin-top: 8.5vh;

    h1 {
      font-size: 48px;
      text-transform: uppercase;
      text-align: center;
      font-family: "Myriadpro-Bold";
      color: var(--primary-green);
    }
  }

  .result-score {
    border: 0.5px solid #707070;
    display: flex;
    height: 180px;
    min-height: 180px;
    margin-top: 5.5vh;

    .score {
      position: relative;
      border-right: 0.5px solid #5a5a5a;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36%;

      .title {
        position: absolute;
        top: -28px;
        text-align: center;
        color: #6d6e71;
        font-family: "Myriadpro-Light";
        font-size: 22px;
      }

      .number-score {
        color: #df2626;
        font-size: 120px;
        font-family: "iCiel-Alina";
        line-height: 86px;
      }
    }

    .note {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      padding: 24px;

      .title {
        position: absolute;
        top: -28px;
        left: 10%;
        color: #6d6e71;
        font-family: "Myriadpro-Light";
        font-size: 22px;
      }

      .text-note {
        color: #df2626;
        font-size: 41px;
        font-family: "iCiel-Alina";
      }
    }
  }

  .result-info-exam {
    margin-top: 36px;
    flex: 1;

    .item-info {
      display: flex;
      align-items: center;

      .text {
        color: #6d6e71;
        font-family: "Myriadpro-Light";
        font-size: 24px;
        width: 320px;
      }

      .info {
        color: #df2626;
        font-family: "Myriadpro-Bold";
        font-size: 24px;
      }
    }
  }

  .result-action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;

    @media screen and (max-height: 800px) {
      margin-bottom: 0 !important;
    }

    .btn-action {
      background-image: linear-gradient(to right, #00b9b7, #00e1a0);
      display: flex;
      border: none;
      padding: 7.5px 16px;
      margin: 0px 6px;
      border-radius: 20px;
      align-items: center;
      color: #fff;
      font-size: 18px;
      font-family: "Myriadpro-SemiBold";
      justify-content: center;
    }
  }
}

.modal-review-exam {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  .layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: gray;
    opacity: 0.5;
    z-index: 1;
  }

  .modal-form {
    position: relative;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 24px;
    width: 60%;
    max-height: 80vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-review-exam {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      max-height: 80%;
      padding: 0 40px;

      .question-name {
        text-align: center;
        font-weight: 600;
        font-size: larger;
      }

      .content-question {
        width: 100%;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .col-6 {
        width: 50%;
      }

      .col-12 {
        width: 100%;
      }
    }
  }

  .btn-close-review-container {
    padding-top: 16px;
  }

}