.entrance_score_container {
  padding-bottom: 0;
  overflow: hidden !important;
}

.sunE-right-container.entrance_score_container {
  padding: 30px 14px 0 14px;
}

.student-avatar-box {
  align-self: flex-start;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  box-shadow: 2px 2px 5px 0px #00000040;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.student-info {
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .student-info-name {
    font-size: 16px;
    font-weight: 700;
  }


  .student-info-label-ico {
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 6px;
  }

}

.size-text-info {
  font-size: 14px;
}

.student-info-score {
  color: red;
  font-weight: 700;
  margin-left: 3px;
}