.questions {
  flex-direction: column;

  .vocab-title {
    font-weight: 700;
  }

  .vocab-right-ans {
    color: #62B440;
  }
}