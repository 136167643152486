.license-page {
  .sunE-content-box {
    padding-left: 0;
  }
  .license-content {
    flex: 1;
    overflow-y: auto;
    max-height: 60rem;
    align-items: flex-start;

    .img-license-empty {
      width: 40%;
      min-width: 20rem;
    }

    .license-text-empty {
      font-size: clamp(1.5rem, 2vw, 1.625rem);
    }

    .license-text-instruct {
      padding-top: 0.5rem;
      font-size: clamp(1.125rem, 2vw, 1.25rem);
    }
  }

  .license-btn {
    padding-bottom: 3vh;
    padding-top: 2vh;

    button {
      padding: 0.25rem 0.75rem;
    }

    .btn-line-blue {
      &:hover {
        background: #00c0b4 !important;
      }
    }

    .btn__icon {
      height: 80%;

      img {
        height: 100%;
      }
    }

    .btn__icon-package {
      height: 90%;
    }
  }

  .package-list {
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: calc(2vh + 0.5rem);
    .package-item {
      background-color: var(--white-color);
      padding: 0.65rem 0.75rem;
      width: 82%;
      align-self: center;
      border-radius: 1rem;
      margin: 0.75rem 0;

      .package-img {
        padding-right: calc(3% + 0.5rem);
        img {
          width: 8.75rem;
          height: 8.75rem;
        }
      }

      .package-infor {
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        padding-right: 2rem;

        .package-infor_name {
          font-size: clamp(1.125rem, 1.8vw, 1.25rem);
          span {
            font-weight: 500;
            font-style: italic;
            font-size: clamp(0.875rem, 1.5vw, 1rem);
          }
        }

        .package-infor_date {
          div {
            font-size: clamp(1rem, 1.5vw, 1.125rem);
          }
        }

        .package-infor_btn {
          button {
            height: 2.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            border-radius: 1.25rem;
          }
        }
      }
    }
  }

  .list-buy-history {
    flex: 1;
    overflow-y: auto;
    max-height: 50rem;
    align-items: flex-start;
  }
  .history-list {
    .history-item {
      width: 82%;
      align-self: center;

      .history-infor {
        padding: 1rem 1.5rem 1rem 1.25rem;

        .history-icon {
          margin-right: calc(2% + 0.5rem);
          img {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }
    }

    .history-header {
      background-color: #60d6d0;
      height: 3.75rem;
      padding: 0.5rem 1.5rem 0.5rem 2.25rem;
      border: 1px solid #60d6d0;
      margin-bottom: 2.5px;
      position: relative;

      div {
        font-size: clamp(1.8rem, 1.8vw, 1.875rem);
        line-height: normal;
      }
    }

    .history-package_title {
      font-size: clamp(1rem, 1.75vw, 1.125rem);
    }

    .history-package_detail {
      font-size: 0.875rem;
    }
  }
}

.history-page {
  padding-bottom: 0;
}

// Add License
.add-license {
  flex: 1;
  overflow-y: auto;
  max-height: 62rem;
  align-items: flex-start;

  div,
  span {
    font-family: "Myriadpro-Regular" !important;
    line-height: normal;
  }

  .content {
    .content_bg {
      .list_packages {
        padding: 0;

        .package_item {
          margin-top: 1rem;

          min-width: 16.75rem;

          .package_item_header {
            padding: 0.75rem 0 !important;

            .ico_white_star {
              width: 0.875rem;
            }
          }

          .item_detail_fee {
            font-size: clamp(0.75rem, 1.75vw, 0.875rem) !important;
            margin-right: 1rem;
            margin-left: 1rem;
            width: 88.5%;
          }

          .package_item_title {
            font-size: clamp(1rem, 1.75vw, 1.125rem);
            font-family: "Myriadpro-Bold" !important;
            position: relative;
            top: 0.155rem;
          }

          .package_item_content {
            padding: 0 5% !important;
          }

          .ico_tick_detail {
            width: 0.875rem;
            top: 0.75rem;
          }

          .ico_tick_detail_1 {
            width: 0.875rem;
            top: 0.75rem;
          }

          .item_detail_fee_side {
            margin-left: 0 !important;
          }

          .package_item_price {
            padding-top: 1rem !important;
            margin-bottom: 0.75rem !important;
            .original_price_item {
              font-size: clamp(0.75rem, 1.75vw, 0.875rem) !important;
            }
            .original_price_item_unit {
              font-size: clamp(0.65rem, 1.75vw, 0.75rem) !important;
            }
          }
          .current_price_item {
            font-size: clamp(1.5rem, 2.5vw, 1.75rem);
            font-family: "Myriadpro-Bold" !important;
            position: relative;
            top: 0.155rem;
          }

          .current_price_item_unit {
            font-size: clamp(1.125rem, 2.5vw, 1.3rem) !important;
            font-family: "Myriadpro-Bold" !important;
            bottom: 0.15rem;
          }

          .btn_custom {
            background-image: var(--linear-color);
            border-radius: 2rem;
            width: 82% !important;
            max-width: 90% !important;
            height: 2.5rem !important;
            div {
              color: var(--white-color);
              font-family: "Myriadpro-Regular" !important;
              text-transform: initial !important;
              font-size: clamp(0.875rem, 2.5vw, 1.05rem);
            }
          }
        }

        .package_item_tariff {
          width: 45%;

          min-width: 20.75rem;
          min-height: 60rem !important;
          margin-top: 0.75rem;

          .package_item_title {
            font-size: clamp(1.25rem, 2vw, 1.42rem);
          }

          .pdElement {
            padding-top: 0.6rem !important;
            padding-bottom: 0.6rem !important;
          }

          .noPdElement_side {
            padding-left: 1rem !important;
          }

          .package_item_price_tariff {
            padding-top: 0.15rem !important;
            margin-bottom: 0.25rem !important;
            line-height: normal;
          }

          .tariff_item_accounts {
            min-height: 6rem;
            padding-bottom: 1.25rem !important;
            padding-top: 1rem !important;
          }

          .item_detail_fee,
          .text_desc_diamond {
            font-size: clamp(0.875rem, 2vw, 1rem) !important;
            margin-right: 0.75rem !important;
          }

          .btn_custom {
            width: 75% !important;
            max-width: 82% !important;
            div {
              font-size: clamp(1rem, 3.2vw, 1.135rem);
            }
          }

          .current_price_item {
            font-size: clamp(2.25rem, 3.2vw, 2.5rem);
            font-family: "Myriadpro-Bold" !important;
            position: relative;
            top: 0.155rem;
          }

          .current_price_item_unit {
            font-size: clamp(1.8rem, 3.2vw, 1.875rem) !important;
            font-family: "Myriadpro-Bold" !important;
            bottom: 0.15rem;
          }
        }
      }
    }
  }
}
